<template>
  <div class="default-layout">
    <HoTheHeader class="the-header" />

    <!-- PC表示ではSideMenu固定 -->
    <HoSideMenu :class="['side-menu', { '-loggedin': isRequiredLogin }]" />

    <div
      class="container"
      :class="{
        '-mypage': isMypage,
      }"
    >
      <div class="page">
        <div class="inner">
          <HoContentLoading :loaded="!isShowLoading" />
          <template
            v-if="!isRequiredLogin || (isRequiredLogin && isMyProfileAcquired)"
          >
            <slot />
          </template>
        </div>
      </div>
    </div>

    <HoSpToolbar />

    <!-- ▼▼▼ 未ログイン時、ログインを促すダイアログ ▼▼▼ -->
    <ClientOnly>
      <template v-if="isRequiredLogin && !isGottenMe">
        <HoConfirmDialog
          class="confirm-dialog"
          :is-open="isOpenDialogLogin"
          :message="i18n.t('dialog.login.message')"
          :close-message="i18n.t('dialog.login.close')"
          :confirm-message="i18n.t('dialog.login.confirm')"
          @confirm="initAuth"
          @cancel="redirectTop"
          @close="redirectTop"
        />
      </template>
    </ClientOnly>
    <!-- ▲▲▲ 未ログイン時、ログインを促すダイアログ ▲▲▲ -->

    <!-- ▼▼▼ PWAインストールを促すモーダル ▼▼▼ -->
    <!-- NOTE: 表示要素のz-indexの関係でlayoutsに配置しています -->
    <template v-if="isPwaShowPage">
      <HoPwaRecommendInstall />
    </template>
    <!-- ▲▲▲ PWAインストールを促すモーダル ▲▲▲ -->

    <!-- ▼▼▼ 通知許可モーダル ▼▼▼ -->
    <template v-if="isNotificationShowPage">
      <HoPwaRecommendNotification />
    </template>
    <!-- ▲▲▲ 通知許可モーダル ▲▲▲ -->
  </div>
</template>
<script lang="ts" setup>
import { useGtm, createGtm } from '@gtm-support/vue-gtm'

import HoTheHeader from '@/components/ho/HoTheHeader.vue'

// composables
import { authInjectionKey, useAuth as _useAuth } from '@/composables/useAuth'
import {
  useMyProfile as _useMyProfile,
  myProfileInjectionKey,
} from '@/composables/useMyProfile'
import {
  useProfile as _useProfile,
  profileInjectionKey,
} from '@/composables/useProfile'
import {
  useNotification as _useNotification,
  notificationInjectionKey,
} from '@/composables/useNotification'
import {
  useToast as _useToast,
  toastInjectionKey,
} from '@/composables/useToast'
import {
  useMypageMenu as _useMypageMenu,
  mypageMenuInjectionKey,
} from '@/composables/useMypageMenu'
import {
  useContact as _useContact,
  contactComposableInjectionKey,
} from '@/composables/useContact'
import {
  usePwaRecommend as _usePwaRecommend,
  pwaRecommendInjectionKey,
} from '@/composables/usePwaRecommend'
import {
  useWebPush as _useWebPush,
  webPushInjectionKey,
} from '@/composables/useWebPush'

// modules
import { isVisible } from '@/utils/browser'
import { PWA_LAUNCHED_KEY } from '@/utils/constants'
import { isPwa } from '@/utils/user-agent'

const router = useRouter()
const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

/** composables */
const authStore = _useAuth()
const root = useRoot()
const useToast = _useToast()
const useMyProfile = _useMyProfile()
const useProfile = _useProfile()
const useNotification = _useNotification()
const useMypageMenu = _useMypageMenu()
const useContact = _useContact()
const usePwaRecommend = _usePwaRecommend()
const useWebPush = _useWebPush()

provide(authInjectionKey, authStore)
provide(rootInjectionKey, root)
provide(toastInjectionKey, useToast)
provide(myProfileInjectionKey, useMyProfile)
provide(profileInjectionKey, useProfile)
provide(notificationInjectionKey, useNotification)
provide(mypageMenuInjectionKey, useMypageMenu)
provide(contactComposableInjectionKey, useContact)
provide(pwaRecommendInjectionKey, usePwaRecommend)
provide(webPushInjectionKey, useWebPush)

const { getNotificationSummary } = useNotification
const { state, startLoading, endLoading, setIsDisplaySPMenu } = root
const {
  state: myProfileState,
  getMyProfile,
  isMyProfileAcquired,
} = useMyProfile
const { isLoading } = useVrmViewer()
const { init: pwaRecommendInit } = usePwaRecommend
const { init: webPushInit } = useWebPush
const { me, isGottenMe, initAuth, isRequiredLogin } = authStore

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const { localePath } = useLocale()

const { addToast } = useToast

/* gtm */
// todo: app.vue に入れるとnuxtの初期化前でエラーになる。全layout共通処理はdefault.vueから分離したい
const loadGtm = () => {
  // NOTE: ログイン状態をGTMに送信したいので、ここで初期化する
  window.dataLayer = window.dataLayer || []
  // NOTE: 既に初期化されている場合は何もしない
  if (window.dataLayer.length === 0) {
    window.dataLayer.push({
      vket_id: me.value?.vketId ?? '',
      is_logged_in: isGottenMe.value,
    })
    const gtm = useGtm()
    if (gtm?.id) return
    nuxtApp.vueApp.use(
      createGtm({
        id: config.public.NUXT_ENV_GTM_ID,
        debug: config.public.NUXT_ENV_OUTPUT_ENV !== 'production',
      })
    )
  }

  // PWAで起動していたらイベントを送信
  // 初回起動である
  const isPwaLaunched = getLocalStorageValue(PWA_LAUNCHED_KEY)
  if (!isPwaLaunched && isPwa()) {
    const gtm = useGtm()
    gtm?.trackEvent({
      event: 'activation_PWA',
      is_logged_in: isGottenMe.value,
      referrer: process.client ? document?.referrer : '',
    })
    setLocalStorageValue(PWA_LAUNCHED_KEY, 'true')
  }
}

watch(isGottenMe, async (newVal) => {
  if (newVal) {
    await webPushInit()
    pwaRecommendInit()
  }
})

/** reactive */
const images = ref<HTMLImageElement[]>([])
const fetchedImages = ref(0)
const route = useRoute()
const isOpenDialogLogin = ref(false)

/** computed */
const isPwaShowPage = computed(() => {
  const targetPagePathArr = ['/', '/en/']
  return targetPagePathArr.includes(route.path)
})
const isNotificationShowPage = computed(() => {
  const targetPagePathArr = ['/', '/en/']
  return targetPagePathArr.includes(route.path)
})
const isMypage = computed(() => /mypage/.test(route.fullPath))
// 画像全てロードしたか？
const isImagesLoaded = ref(false)
const isShowLoading = computed(() => {
  if (state.isLoading) return true
  if (isImagesLoaded.value === false) return true
  return false
})

const redirectTop = () => {
  location.replace(localePath('/'))
}

/** 画像読み込みチェック */
nextTick(() => {
  if (process.server) return
  images.value = Array.from(document.querySelectorAll('img,video'))
  if (images.value.length === 0) {
    isImagesLoaded.value = true
    return
  }

  for (let i = 0; i < images.value.length; i++) {
    if (!images.value[i]) return
    const elm = images.value[i] as HTMLImageElement
    // 画像読み込み完了したときの処理
    elm.addEventListener('load', (e) => {
      fetchedImages.value += 1
      if (fetchedImages.value >= images.value.length - 1) {
        isImagesLoaded.value = true
      }
    })
    // 遅延読み込み
    const src = elm.getAttribute('src')
    if (src) elm.src = src
  }

  // 2秒たっても読み込みが終わらない場合、強制的に完了にする
  setTimeout(() => {
    isImagesLoaded.value = true
  }, 2000)
})

/**
 * マイページヘッダースクロール監視
 * */
onMounted(async () => {
  if (isGottenMe.value) {
    await webPushInit()
    pwaRecommendInit()
  }
})

// 定期的に通知サマリを取得する
let notificationInterval: NodeJS.Timer | undefined
const checkNotificationSummary = () => {
  notificationInterval = setInterval(() => {
    // 画面がユーザーに表示されていればサマリ取得
    if (isVisible()) getNotificationSummary()
  }, 30000) // 30秒に一回
}

/**
 * 認証チェック
 * 1.画面リフレッシュ時(onBeforeMountで発火)
 *   1-1.ログイン後のリダイレクト時 -> initAuthAfterLogin()
 *   1-2.通常の画面表示 -> initAuth()
 * 2.Vue-Routerでの画面遷移時(route.pathをwatchして発火) -> initAuth()
 * */
// 画面リフレッシュ時
onBeforeMount(async () => {
  try {
    startLoading()
    // 通常時
    const authResult = await initAuth()
    if (authResult) {
      if (isRequiredLogin) {
        // ユーザーに手動ログインを促す
        isOpenDialogLogin.value = true
        return
      }
      addToast(i18n.t(authResult), 'error')
      return
    }
    if (isGottenMe.value) {
      const profile = await getMyProfile()
      if (profile && !profile.isCompleteTutorial) {
        // チュートリアル時のリダイレクト設定
        setLocalStorageValue(
          LOCAL_STORAGE_TUTORIAL_REDIRECT_URL_KEY,
          route.fullPath
        )
        await router.replace(isJa ? '/tutorial' : '/en/tutorial')
      } else {
        // お知らせサマリー取得（非同期でエラー出さない）
        getNotificationSummary()
        checkNotificationSummary()
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    endLoading()
    loadGtm()
  }
})

// レイアウト変更時
onBeforeUnmount(() => {
  clearInterval(notificationInterval)
})

const head = useLocaleHead({
  addSeoAttributes: true,
})

useHead({
  link: [...(head.value.link || [])],
  htmlAttrs: {
    class: isSafari() ? '-safari' : '',
    prefix: 'og: https://ogp.me/ns#',
    lang: i18n.locale.value,
  },
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

/** layout */
.default-layout {
  > .the-header {
    height: v.$header-height-pc;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: height 0.3s ease;
    width: 100%;
    z-index: v.$zindex-header;

    &.-hidden {
      height: 0;
    }

    @include m.sp() {
      height: v.$header-height-sp;
    }
  }

  > .side-menu {
    @include m.tb() {
      display: none;
    }
  }

  > .container {
    margin-left: auto;
    margin-top: v.$header-height-pc;
    transition: margin-top 0.3s ease;
    width: calc(100% - v.$side-menu-width);

    @include m.tb {
      padding-bottom: v.$sp-toolbar-height;
      width: 100%;
    }

    @include m.sp {
      margin-left: 0;
      margin-top: v.$header-height-sp;
    }
  }

  > .container > .page {
    min-height: calc(100vh - v.$header-height-pc - 320px);
    @include m.sp() {
      min-height: 0;
    }

    @include m.landscape() {
      min-height: v.$content-height-landscape;
    }

    > .inner {
      min-height: calc(100vh - v.$header-height-pc - 320px);
      position: relative;
      z-index: 1;
      @include m.landscape() {
        min-height: v.$content-height-landscape;
      }
    }
  }

  // エラーページのレイアウト
  &.-error-page > .container > .page {
    display: flex;
    flex-direction: column;
  }
}
</style>
